import React from 'react';
import { useDataContext } from '../../DataContext';

const TOS = () => {
    const { history } = useDataContext()
    return (
        <div className='bgimg'>
            <div className='header'>
                <button className='backHeader' onClick={() => history('/')}>Back</button>
            </div>
            <div className="tos-container">
            <h1>Terms of Service</h1>

            <div className="section">
                <h2>1. Úvod</h2>
                <p>Děkujeme, že používáte naši aplikaci Alkoholický deník. Tyto zásady ochrany soukromí byly vytvořeny s cílem vysvětlit, jakým způsobem shromažďujeme, používáme a uchováváme informace od našich uživatelů.</p>
            </div>

            <div className="section">
                <h2>2. Shromažďování a Používání Informací</h2>
                <div className="subsection">
                <h3>2.1 Typy Informací</h3>
                <p>Aplikace Alkoholický deník shromažďuje pouze informace, které zadáváte při jejím používání. To zahrnuje:</p>
                <ul>
                    <li>Základní Identifikační Údaje:</li>
                    <ul>
                    <li>Uživatelské jméno</li>
                    <li>E-mailová adresa</li>
                    </ul>
                    <li>Obsah vytvořený Uživatelem:</li>
                    <ul>
                    <li>Přidané nápoje</li>
                    <li>Odeslané statistiky</li>
                    </ul>
                </ul>
                </div>

                <div className="subsection">
                <h3>2.2 Účel Shromažďování</h3>
                <p>Shromažďujeme tyto informace výhradně za účelem poskytování, zlepšování a personalizace služeb v rámci naší aplikace.</p>
                </div>
            </div>

            <div className="section">
                <h2>3. Bezpečnost a Šifrování</h2>
                <p>Všechny citlivé informace jsou šifrovány.</p>
            </div>

            <div className="section">
                <h2>4. Transparentnost a Kontrola</h2>
                <div className="subsection">
                <h3>4.1 Oprava a Odstranění Dat</h3>
                <p>Pokud si přejete aktualizovat nebo odstranit své osobní údaje, kontaktujte nás na martin.kubjak@gmail.com.</p>
                </div>
            </div>

            <div className="section">
                <h2>5. Sdílení s Třetími Stranami</h2>
                <p>Vaše informace nebudou sdíleny s třetími stranami bez vašeho výslovného souhlasu, s výjimkou případů, kdy je to nezbytné pro dodržení zákonů.</p>
            </div>

            <div className="section">
                <h2>6. Doba Uchovávání Dat</h2>
                <p>Informace budou uchovávány pouze po dobu nezbytně nutnou k dosažení účelu, kvůli kterému byly shromážděny.</p>
            </div>

            <div className="section">
                <h2>7. Aktualizace Zásad Ochrany Soukromí</h2>
                <p>Tyto zásady ochrany soukromí mohou být pravidelně aktualizovány. O každé změně budete informováni prostřednictvím upozornění v aplikaci.</p>
            </div>

            <div className="section">
                <h2>8. Kontakt</h2>
                <p>V případě dotazů nebo obav ohledně ochrany soukromí nás kontaktujte na martin.kubjak@gmail.com.</p>
            </div>
            <div className="section">
                <h2>9. Omezení odpovědnosti za přesnost a úplnost informací</h2>
                <p>Veškerá data a informace zobrazené v této aplikaci slouží pouze pro informativní účely. Uživatel bere na vědomí, že tato data nemusí být vždy přesná, aktuální ani definitivní a mohou se lišit od skutečnosti. Společnost nenese odpovědnost za případné nesrovnalosti či chyby v poskytovaných údajích. Uživatelé jsou povinni ověřit si informace z jiných zdrojů před tím, než učiní jakékoli závazné rozhodnutí na základě zobrazených dat.</p>
            </div>
            </div>
        </div>
    );
};

export default TOS;
