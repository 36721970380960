import React, { useContext, useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import useAsyncState from './hook/useAsyncState'
import jwt from 'jwt-decode'
import forge from 'node-forge'
const DataContext = React.createContext()
const apiLink = process.env.REACT_APP_API_LINK

export function useDataContext(){
    return useContext(DataContext)
}

export function DataProvider({ children }){
    const history = useNavigate()
    const [cookies, setCookies, removeCookies] = useCookies()
    const [token, setToken] = useAsyncState('')
    const [user, setUser] = useAsyncState('')
    
    useEffect(() => {
        if(cookies.token){
            setToken(cookies.token)
            setUser(jwt(cookies.token).user_data)
        }
    }, [])

    const send_fetch_request = async (endpoint, options) => {
        const secretKey =  process.env.REACT_APP_SECRET_KEY
        const iv = forge.random.getBytesSync(16);
    
        if(options.method == undefined || options.method == "GET" || options.method == "get") return fetch(endpoint, options)
        
        const cipher = forge.cipher.createCipher('AES-CBC', secretKey)
        cipher.start({ iv: iv })
        cipher.update(forge.util.createBuffer(options?.body))
        cipher.finish()
        const encryptedData = forge.util.encode64(cipher.output.getBytes())    
        options.body = JSON.stringify({ data: encryptedData, iv: forge.util.encode64(iv) })
    
        // console.log(endpoint, options)
    
        return await fetch(endpoint, options)
    }

    const login = async (email, password) => {
        if(email.length < 0 || password.length < 0) return
        let res = await send_fetch_request(apiLink + '/auth/admin_sign_in', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ email, password })
        })        
        let data = await res.json()
        if(data.err) return data
        await setToken(data.token)
        await setUser(jwt(data.token).user_data)
        setCookies("token", data.token, {secure: true, sameSite: 'none'})
        return data
    }


    const value = {
        login,
        token,
        apiLink,
        history,
        send_fetch_request
    }

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    )
}